import { omit } from "lodash";
import { AnyAggregationField } from "../../../aggregations";
import { BaseEntity } from "../../../db";
import { DotNestedKeys } from "../../../utils/flatten";
import { IntegrationType } from "../../task-types";

const commonFieldsToRemove: DotNestedKeys<BaseEntity<object>>[] = [
  "createdAt",
  "modifiedAt",
  "index"
];

const fieldsToRemoveOnCreate: DotNestedKeys<BaseEntity<object>>[] = [
  ...commonFieldsToRemove,
  "id"
];

const fieldsToRemoveOnUpdate: DotNestedKeys<BaseEntity<object>>[] = [
  ...commonFieldsToRemove
];

export const removeBaseFields = <T extends object>(r: {
  entity: T;
  operationType?: "create" | "update";
}): Partial<T> => {
  const keysToRemove =
    !r.operationType || r.operationType === "create"
      ? fieldsToRemoveOnCreate
      : fieldsToRemoveOnUpdate;

  return omit(r.entity, keysToRemove);
};

export const buildIntegrationTemplate = (r: {
  integrationFields: Record<string, AnyAggregationField>;
  integrationType: IntegrationType;
  vendor: string;
}) => {
  const { integrationFields, integrationType, vendor } = r;
  return {
    [integrationType]: {
      [vendor]: integrationFields
    }
  };
};
